<template>
  <div class="createCoupons">
    <div class="title">
      {{
        type == "add" || type == "copy"
          ? "创建新优惠券"
          : type == "edit"
          ? "编辑优惠券"
          : "优惠券详情"
      }}
    </div>
    <div class="cell">
      <div class="titles">基本详情</div>
      <div class="form-cell">
        <div class="form-item">
          <div class="label">优惠券类型</div>
          <div class="right-val" style="display: flex; align-items: center">
            <div
              class="float-pop"
              v-if="type == 'edit' || type == 'info'"
            ></div>
            <div
              class="btn1"
              style="margin-right: 10px"
              :class="coupon_type == 1 ? 'btn2' : ''"
              @click="handleQH(1)"
            >
              <div class="icon">
                <img :src="shopeImg" alt="" />
              </div>
              <div>店铺优惠券</div>
              <div class="sanjiao" :class="coupon_type == 2 ? 'sanjiao2' : ''">
                <i
                  class="el-icon-check"
                  style="color: #fff; font-size: 8px"
                ></i>
              </div>
            </div>
            <div
              class="btn1"
              :class="coupon_type == 2 ? 'btn2' : ''"
              @click="handleQH(2)"
            >
              <div class="icon">
                <img :src="goodsImg" alt="" />
              </div>
              <div>商品优惠券</div>
              <div class="sanjiao" :class="coupon_type == 1 ? 'sanjiao2' : ''">
                <i
                  class="el-icon-check"
                  style="color: #fff; font-size: 8px"
                ></i>
              </div>
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label" style="margin-top: -20px">优惠券名称</div>
          <div class="right-val">
            <el-input
              :disabled="type == 'info'"
              size="small"
              v-model="form.coupon_name"
              placeholder="请输入"
              style="width: 100%"
            ></el-input>
            <div style="font-size: 12px; color: #7f7f7f; margin-top: 4px">
              {{
                coupon_type == 2 ? "商品" : ""
              }}优惠券的名称对于买家是不可见的。
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label" style="margin-top: -38px">优惠码</div>
          <div class="right-val">
            <el-input
              :disabled="type == 'info' || type == 'edit'"
              maxlength="5"
              show-word-limit
              size="small"
              placeholder="请输入"
              v-model="couponCode"
              style="width: 100%"
              @input="
                form.coupon_code = form.coupon_code.replace(
                  /[\u4E00-\u9FA5]/g,
                  ''
                )
              "
            >
              <template slot="prepend">
                <span @click="hotCode" style="cursor: pointer">
                  {{ code }}</span
                >
              </template>
            </el-input>
            <div style="font-size: 12px; color: #7f7f7f; margin-top: 4px">
              请输入英文字A-Z 0-9最多输入五个字
            </div>
            <div style="font-size: 12px; color: #7f7f7f; margin-top: 2px">
              您的完整优惠码是:{{ code }}{{ couponCode }}
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label">优惠券领取期限</div>
          <div class="right-val">
            <el-date-picker
              :disabled="type == 'info' || type == 'edit'"
              size="small"
              v-model="start_time"
              type="datetime"
              prefix-icon="el-icon-date"
              placeholder="选择开始时间"
              default-time="00:00:00"
              value-format="timestamp"
              style="width: 192px"
            >
            </el-date-picker>
            <span style="margin: 0 10px; color: #c0c4cc">—</span>
            <el-date-picker
              :disabled="type == 'info'"
              size="small"
              v-model="end_time"
              type="datetime"
              prefix-icon="el-icon-date"
              placeholder="选择结束时间"
              style="width: 192px"
              default-time="23:59:59"
              value-format="timestamp"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="form-item">
          <div class="label">折扣类型|优惠限额</div>
          <div class="right-val">
            <el-input
              :disabled="type == 'info' || type == 'edit'"
              placeholder="请输入"
              v-model="form.discount_amount"
              @input="handleInput"
              class="input-with-select"
              size="small"
              style="width: 100%"
            >
              <div slot="prepend" style="width: 120px; display: flex">
                <div style="">
                  <el-select
                    :disabled="type == 'info' || type == 'edit'"
                    v-model="form.discount_type"
                    @change="handleSelect"
                    placeholder="请选择"
                  >
                    <el-option label="折扣金额" :value="1"></el-option>
                    <el-option label="折扣" :value="2"></el-option>
                  </el-select>
                </div>
                <div
                  v-if="form.discount_type == 1"
                  style="
                    margin-left: 20px;
                    margin-right: -10px;
                    padding-left: 10px;
                    position: relative;
                  "
                >
                  <div
                    style="
                      width: 1px;
                      height: 32px;
                      background: rgb(220, 223, 230);
                      position: absolute;
                      left: 0;
                      top: -7px;
                    "
                  ></div>
                  RM
                </div>
              </div>
              <template v-if="form.discount_type == 2" slot="append"
                >%折扣</template
              >
            </el-input>
          </div>
        </div>
        <div class="form-item" v-if="form.discount_type == 2">
          <div
            class="label"
            :style="{ 'margin-top': form.max_type == 1 ? ' -32px' : '0' }"
          >
            最高优惠金额
          </div>
          <div class="right-val">
            <div style="padding: 10px 0">
              <el-radio
                @change="handleRadio"
                :disabled="type != 'add' && type != 'copy'"
                v-model="form.max_type"
                label="1"
                >设置金额</el-radio
              >
              <el-radio
                @change="handleRadio"
                :disabled="type != 'add' && type != 'copy'"
                v-model="form.max_type"
                label="2"
                >无限制</el-radio
              >
            </div>
            <el-input
              v-if="form.max_type == 1"
              :disabled="type == 'info' || type == 'edit'"
              size="small"
              placeholder="请输入"
              v-model="form.max_amount"
              style="width: 100%"
            >
              <template slot="prepend">RM</template>
            </el-input>
          </div>
        </div>
        <div class="form-item">
          <div class="label">最低消费金额</div>
          <div class="right-val">
            <el-input
              :disabled="type == 'info' || type == 'edit'"
              size="small"
              placeholder="请输入"
              v-model="form.min_amount"
              style="width: 100%"
            >
              <template slot="prepend">RM</template>
            </el-input>
          </div>
        </div>
        <div class="form-item">
          <div class="label" style="margin-top: -20px">可使用数量</div>
          <div class="right-val">
            <el-input
              :disabled="type == 'info'"
              size="small"
              v-model="form.available_number"
              placeholder="请输入"
            ></el-input>
            <div style="font-size: 12px; color: #7f7f7f; margin-top: 4px">
              可供所有卖家使用的优惠券总数
            </div>
          </div>
        </div>
        <div class="form-item" style="align-items: start">
          <div class="label" style="margin-top: 7px">优惠商品</div>
          <div style="width: 70%">
            <div v-if="coupon_type == 1" style="line-height: 32px">
              全部商品
            </div>
            <div v-else>
              <div v-if="goodsList.length == 0">
                <el-button
                  v-if="type == 'add' || type == 'copy'"
                  @click="handleAddGoods"
                  style="color: #ee4d2d; border-color: #ee4d2d"
                  size="small"
                  plain
                  icon="el-icon-plus"
                  >添加商品</el-button
                >
              </div>
              <div style="width: 100%" v-else>
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 14px;
                  "
                >
                  <div>
                    {{ goodsList.length }}
                    <span style="color: #b0b0b0">已选商品</span>
                  </div>
                  <div>
                    <el-button
                      v-if="type == 'add' || type == 'copy'"
                      @click="handleAddGoods"
                      style="color: #ee4d2d; border-color: #ee4d2d"
                      size="small"
                      plain
                      icon="el-icon-plus"
                      >添加商品</el-button
                    >
                  </div>
                </div>
                <div style="border: 1px solid #eee; margin-top: 20px">
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      padding: 10px 20px;
                      background: #f6f6f6;
                    "
                  >
                    <div style="width: 30%">商品</div>
                    <div style="width: 20%">原价</div>
                    <div style="width: 20%">库存</div>
                    <div style="width: 15%" v-if="type == 'add'">操作</div>
                  </div>
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      padding: 10px 0;
                      font-size: 14px;
                      border-bottom: 1px solid #eee;
                      margin: 0 20px;
                    "
                    v-for="(item, i) in goodsList"
                    :key="item.id"
                  >
                    <div style="width: 30%; display: flex; align-items: center">
                      <div style="margin-right: 10px">
                        <el-image
                          style="width: 40px; height: 40px"
                          :src="item.image"
                          fit="fit"
                        ></el-image>
                      </div>
                      <div class="single-hidden">{{ item.title }}</div>
                    </div>
                    <div style="width: 20%">RM{{ item.price }}</div>
                    <div style="width: 20%">{{ item.stock }}</div>
                    <div
                      style="width: 15%"
                      v-if="type == 'add' || type == 'copy'"
                    >
                      <div
                        @click="handleDel(i)"
                        style="
                          width: 36px;
                          height: 36px;
                          border: 1px solid #eee;
                          border-radius: 50%;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          cursor: pointer;
                          font-size: 16px;
                        "
                      >
                        <i class="el-icon-delete"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        display: flex;
        justify-content: right;
        align-items: center;
        margin-top: 20px;
      "
    >
      <div v-if="type != 'info'">
        <el-button size="medium" @click="toBack" plain>取消</el-button>
        <el-button type="primary" @click="addJuan" size="medium"
          >确认</el-button
        >
      </div>
      <div v-else>
        <el-button size="medium" @click="toBack" plain>返回列表页面</el-button>
      </div>
    </div>

    <MarketingGoods ref="goods" :show="dialogVisible"></MarketingGoods>
  </div>
</template>
<script>
import MarketingGoods from "@/components/goods/MarketingGoods.vue";
import { couponAction, couponDetails } from "@/api/marketing.js";
export default {
  components: { MarketingGoods },
  data() {
    return {
      shopeImg: require("@/assets/img/marketing/shope.jpg"),
      goodsImg: require("@/assets/img/marketing/goods.jpg"),
      shopeCode: "",
      goodsCode: "",
      code: "",
      couponCode: "",
      select: "1",
      coupon_type: 1,
      dialogVisible: false,
      type: "add",
      url: "",
      start_time: "",
      end_time: "",
      form: {
        coupon_name: "",
        coupon_code: "",
        start_time: "",
        end_time: "",
        discount_type: 1,
        discount_amount: "",
        max_amount: "0",
        max_type: "2",
        min_amount: "",
        available_number: "",
        discount_goods: [],
      },
      goodsList: [],
      id: 0,
    };
  },
  created() {
    this.hotCode();
    if (sessionStorage.COUPONSTYPE) {
      this.type = sessionStorage.COUPONSTYPE;
      this.$router.options.routes.forEach((item) => {
        if (item.name == "marketingCenters") {
          item.children.forEach((val) => {
            if (val.name == "createCoupons") {
              val.meta.bread[3].name =
                this.type == "edit"
                  ? "编辑优惠券"
                  : this.type == "add" || this.type == "copy"
                  ? "创建优惠券"
                  : "优惠券详情";
            }
          });
        }
      });

      this.type == "edit"
        ? "编辑优惠券"
        : this.type == "add" || this.type == "copy"
        ? "创建优惠券"
        : "优惠券详情";
      // 是否是编辑操作
      if (this.type == "edit" || this.type == "info" || this.type == "copy") {
        this.getInfo();
      }
    } else {
      this.$router.replace("coupons");
    }
  },
  methods: {
    hotCode() {
      if (
        sessionStorage.COUPONSTYPE == "add" ||
        sessionStorage.COUPONSTYPE == "copy"
      ) {
        // 随机生成5位优惠码前缀
        this.shopeCode = Math.random().toString(36).substr(2, 5).toUpperCase();
        this.code = this.shopeCode;
        this.goodsCode = Math.random().toString(36).substr(2, 5).toUpperCase();
      }
    },
    // 获取优惠券详情
    getInfo() {
      couponDetails({
        id: sessionStorage.COUPONSID,
      }).then((res) => {
        if (res.code == 1) {
          // 优惠券类型
          this.coupon_type = res.data.coupon_type;
          // 处理优惠码
          this.code = res.data.coupon_code.substring(0, 5);

          this.shopeCode = this.code;

          this.goodsCode = this.code;

          this.couponCode = res.data.coupon_code.substring(5, 10);

          //  数据赋值表单
          Object.keys(this.form).forEach((item) => {
            Object.keys(res.data).forEach((val) => {
              if (item == val) {
                this.form[item] = res.data[val];
              }
            });
          });
          this.start_time = this.form.start_time * 1000;
          this.end_time = this.form.end_time * 1000;
          // 遍历商品
          if (res.data.discount_goods.length != 0) {
            res.data.discount_goods.forEach((item) => {
              this.goodsList.push({
                id: item.goods_id,
                title: item.goods_name,
                image: item.goods_image,
                price: item.goods_price,
                stock: item.stock,
              });
            });
          }
          // 编辑的时候赋值ID
          if (this.type == "edit") {
            this.id = res.data.id;
          }
          this.form.max_type = res.data.max_amount > 0 ? "1" : "2";
        }
      });
    },
    // 创建优惠券
    addJuan() {
      if (!this.form.coupon_name) {
        this.$message.error("优惠券名称不能为空");
        return false;
      }
      if (!this.couponCode) {
        this.$message.error("优惠码不能为空");
        return false;
      }
      if (!this.start_time) {
        this.$message.error("请选择开始时间");
        return false;
      }
      if (!this.end_time) {
        this.$message.error("请选择结束时间");
        return false;
      }
      this.form.discount_goods = [];
      this.goodsList.forEach((item) => {
        this.form.discount_goods.push({
          goods_id: item.id,
        });
      });

      this.form.start_time = this.start_time / 1000;
      this.form.end_time = this.end_time / 1000;
      this.form.coupon_code = this.code + this.couponCode;
      couponAction({
        id: this.id,
        coupon_type: this.coupon_type,
        ...this.form,
      }).then((res) => {
        if (res.code == 1) {

          this.$message.success(
            this.type == "add" ? "优惠券创建成功" : "优惠券编辑成功"
          );

          this.$router.replace("coupons");
        }
      });
    },
    // 切换优惠券类型
    handleQH(val) {
      this.coupon_type = val;
      // 切换优惠码
      if (val == 1) {
        this.code = this.shopeCode;
      } else {
        this.code = this.goodsCode;
      }
      this.form = {
        coupon_name: "",
        coupon_code: "",
        start_time: "",
        end_time: "",
        discount_type: 1,
        discount_amount: "",
        max_amount: "0",
        max_type: "2",
        min_amount: "",
        available_number: "",
        discount_goods: [],
      };
      this.couponCode = "";
      this.goodsList = [];
      this.start_time = "";
      this.end_time = "";
    },
    // 打开商品列表
    handleAddGoods() {
      this.dialogVisible = true;
    },
    // 选中商品
    handleAndGoods(arr) {
      let list = [];
      this.goodsList.forEach((item) => {
        list.push(item.id);
      });
      arr.forEach((item) => {
        if (list.indexOf(item.id) == -1) {
          this.goodsList.push(item);
        }
      });

      this.dialogVisible = false;
    },
    // 删除商品
    handleDel(index) {
      this.goodsList.splice(index, 1);
      this.$refs.goods.$data.checkedCities.splice(index, 1);
    },
    // 取消
    toBack() {
      this.$router.go(-1);
    },
    handleInput() {
      if (this.form.discount_type == 2) {
        if (this.form.discount_amount > 100) {
          this.form.discount_amount = 100;
        }
      }
    },
    handleSelect() {
      this.form.max_amount = "0";
      this.form.max_type = "2";
    },
    handleRadio() {
      this.form.max_amount = "0";
    },
  },
};
</script>
<style lang="less" scoped>
.createCoupons {
  width: 1400px;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 40px;
  .title {
    font-size: 20px;
  }
  .cell {
    padding: 30px;
    background: #fff;
    margin-top: 20px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    .titles {
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .form-cell {
      margin-top: 20px;
      width: 100%;
      .form-item {
        font-size: 14px;
        display: flex;
        align-items: center;
        padding: 10px 0;
        .label {
          width: 200px;
          text-align: right;
          margin-right: 20px;
        }
        .right-val {
          width: 420px;
          position: relative;
          .float-pop {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            background: rgba(255, 255, 255, 0.5);
            z-index: 3;
            cursor: not-allowed;
          }

          .btn1 {
            width: 208px;
            height: 70px;
            border-radius: 4px;
            border: 1px solid #eee;
            display: flex;
            align-items: center;
            padding: 14px;
            font-size: 14px;

            cursor: pointer;
            position: relative;
            .icon {
              width: 40px;
              height: 40px;
              background: red;
              margin-right: 10px;
            }
            .sanjiao {
              border-left: 14px solid transparent;
              border-right: 14px solid #ee4d2d;
              border-top: 14px solid #ee4d2d;
              border-bottom: 14px solid transparent;
              position: absolute;
              right: 0;
              top: 0;
              z-index: 2;
              .el-icon-check {
                position: absolute;
                top: -10px;
                right: -10px;
              }
            }
            .sanjiao2 {
              border-left: 14px solid transparent;
              border-right: 14px solid #808080;
              border-top: 14px solid #808080;
              border-bottom: 14px solid transparent;
            }
          }
          .btn2 {
            box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);
            border: none;
          }
        }
      }
    }
  }
  .pop-list {
    font-size: 14px;
    border: 1px solid #eee;
    border-radius: 4px;
    .pop-list_head {
      display: flex;
      background: #f6f6f6;
      padding: 10px;
      justify-content: space-between;
    }
    .pop-list_content {
      display: flex;
      padding: 10px;
      justify-content: space-between;
    }
  }
}
</style>